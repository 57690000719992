import styled from '@emotion/styled';

import { Timer } from '../components/timer';
import { DealerInfo } from './dealer-info';
import { PlayerActionDisplay } from './player-action';
import { BoxNumbers } from './dealer-table-numbers';
import { BoxData } from '../contexts/game-context';

import { BlackJackStates, PlayerData } from '@blackjack/models';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border: 0.1rem solid #ffffff;
  background-color: #032799;

  width: 103.3rem;
  height: 55.2rem;
  border-top-left-radius: 55.2rem;
  border-top-right-radius: 55.2rem;

  position: absolute;
  left: 44.3rem;
  top: 37.3rem;
`;

const Padded = styled.div`
  padding-top: 15rem;
`;

export interface IDealerTable {
  boxes?: BoxData[] | undefined;
  currentHand: number;
  currentPlayer: PlayerData | undefined;
  dealersCards: boolean;
  insurance: boolean;
  nextState: BlackJackStates | null;
  noMoreBets: boolean;
  noMoreBetsTimer: number;
  playerActionTimer: number;
}

export const DealerTable = (props: IDealerTable) => {
  const {
    boxes,
    currentHand,
    currentPlayer,
    dealersCards,
    insurance,
    nextState,
    noMoreBets,
    noMoreBetsTimer,
    playerActionTimer,
  } = props;

  const boxNumberData = boxes?.map((box) => {
    return {
      currentTurn: box.playerTurn,
      playerBaseBet: box.player?.bets.base,
      seatNumber: box.seatNumber,
    };
  });

  return (
    <Container>
      <Padded>
        <BoxNumbers boxes={boxNumberData} nextState={nextState} />
        {noMoreBetsTimer > 0 && (
          <Timer endTime={noMoreBetsTimer} onFinish={() => null} />
        )}
        {playerActionTimer !== 0 && (
          <Timer endTime={playerActionTimer} onFinish={() => null} />
        )}
        {insurance && <DealerInfo data="INSURANCE" />}
        {currentPlayer &&
          nextState !== BlackJackStates.WaitingForPlayerAction && (
            <PlayerActionDisplay
              lastAction={currentPlayer.lastActions[currentHand]}
            />
          )}
        {noMoreBets && <DealerInfo data="No more bets" />}
        {dealersCards && <DealerInfo data="Dealers Cards" />}
      </Padded>
    </Container>
  );
};
