import { Routes, Route } from 'react-router-dom';
import { AspectRatioKeeper } from '@shared/ui/components/generic';

import { AppLayout as ActionUI } from './layouts/app-layout';
import { DealerVideoUI } from './components/dealer-video';

export const App = () => {
    return (
        <AspectRatioKeeper>
            <Routes>
                <Route path="/" element={<ActionUI />} />
                <Route path="/video" element={<DealerVideoUI />} />
            </Routes>
        </AspectRatioKeeper>
    );
};
