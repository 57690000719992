import { ReactNode } from 'react';

import styled from '@emotion/styled';

import { boxLocation } from '../utils/box-location';

export interface IBoxLocationProps {
  seatLocation: number;
  children: ReactNode;
}

const Container = styled.div<{
  location: { top: string; left: string } | undefined;
}>`
  position: absolute;
  top: ${({ location }) => {
    return location?.top;
  }};
  left: ${({ location }) => {
    return location?.left;
  }};
`;

export const BoxLocation = (props: IBoxLocationProps) => {
  const { seatLocation, children } = props;

  return <Container location={boxLocation(seatLocation)}>{children}</Container>;
};
