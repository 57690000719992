import { useMemo, useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { BlackJackStates } from '@blackjack/models';

import { DealerTable } from '../components/dealer-table';
import { RoundEnd } from '../components/round-end';
import { PlayerBoxes } from '../components/player-boxes';

import { useGameContext } from '../contexts/game-context';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  background-color: black;
  margin: 0px;
`;

export const AppLayout = () => {
  const { gameState } = useGameContext();
  const { boxData, players, currentState } = gameState;

  const currentPlayer = useMemo(
    () => players.find((p) => p.seat === currentState.seat_id),
    [players, currentState.seat_id]
  );

  const currentHand = currentState.hand;

  const noMoreBetsTimer = useMemo(() => {
    if (currentState.next_state === BlackJackStates.WaitingForBets) {
      return (
        (gameState.roundInfo?.start_time || 0) +
        (gameState.roundInfo?.duration || 0)
      );
    } else return 0;
  }, [currentState.next_state, gameState.roundInfo]);

  const [noMoreBets, setNoMoreBets] = useState(false);

  useEffect(() => {
    if (currentState.next_state === BlackJackStates.FinishedBetting) {
      setNoMoreBets(true);
    }
    if (currentState.next_state === BlackJackStates.WaitingForCards) {
      const timer = setTimeout(() => {
        setNoMoreBets(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentState.next_state]);

  const playerActionTimer = useMemo(() => {
    if (currentState.next_state === BlackJackStates.WaitingForPlayerAction) {
      return (
        (gameState.currentAction?.start_time || 0) +
        (gameState.currentAction?.duration || 0)
      );
    }
    return 0;
  }, [
    currentState.next_state,
    gameState.currentAction?.start_time,
    gameState.currentAction?.duration,
  ]);

  const [insurance, setInsurance] = useState(false);

  useEffect(() => {
    if (gameState.insurance) {
      setInsurance(true);
    } else {
      const timer = setTimeout(() => {
        setInsurance(false);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [gameState.insurance]);

  const dealersCards = useMemo(() => {
    if (currentState.next_state === BlackJackStates.WaitingForDealerCard) {
      return true;
    } else return false;
  }, [currentState.next_state]);

  const roundEnd = useMemo(() => {
    if (currentState.next_state === BlackJackStates.GameEnd) {
      return true;
    } else return false;
  }, [currentState.next_state]);

  const DealerTableProps = {
    currentHand,
    currentPlayer,
    dealersCards,
    insurance,
    nextState: currentState.next_state,
    noMoreBets,
    noMoreBetsTimer,
    playerActionTimer,
    boxes: boxData,
  };

  const PlayerBoxesProps = {
    currentState,
    boxes: boxData,
  };

  if (roundEnd) {
    return <RoundEnd players={players} />;
  } else {
    return (
      <Container>
        <PlayerBoxes {...PlayerBoxesProps} />
        <DealerTable {...DealerTableProps} />
      </Container>
    );
  }
};

AppLayout.defaultProps = {};
