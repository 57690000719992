import styled from '@emotion/styled';

import { useGameContext } from '../contexts/game-context';
import { VideoStream } from '@shared/ui/components/generic';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    overflow: hidden;
    flex: 1;

    background-image: url('../assets/Table-asset.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 192rem 108rem;

    .video {
        width: 192rem;
        height: 108rem;
        margin-left: calc((192rem * 0.5) * -0.3);
    }
`;

export const DealerVideoUI = () => {
    const { gameState } = useGameContext();

    return (
        <Container>
            {gameState.gameInfo?.streamData && (
                <div className={'video'}>
                    <VideoStream
                        url={gameState.gameInfo.streamData.host}
                        type={gameState.gameInfo.streamData.type}
                        muted={true}
                        onReady={() => null}
                    />
                </div>
            )}
        </Container>
    );
};
