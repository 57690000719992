import { useMemo } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { ReactComponent as BlackJack } from '@blackjack/assets/icons/Blackjack.svg';

import { getLastAction } from '../utils/get-last-action';
import { CurrentState } from '../contexts/game-context';
import { BlackJackStates, IPlayerScore, PlayerAction } from '@blackjack/models';

const Container = styled.div<{ action: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;

    .seat {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Chakra Petch';

        height: 10.7rem;
        width: 20rem;
        flex-direction: row;

        background-color: #111111;
        border: 0.3rem solid #3a3a3a;
        border-radius: 5.35rem;
        transition: all 0.3s ease;

        .player {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
                padding: 2rem;
                height: 4rem;
                width: 8rem;
            }
            .name {
                color: #ffffff;
                font-size: ${({ action }) => {
                    if (action) return '2.4rem';
                    return '4rem';
                }};
                font-family: 'Chakra Petch';
                text-transform: uppercase;
            }
            .action {
                color: #202020;
                font-size: 5.8rem;
                font-family: sans-serif;
            }

            &.blackjack {
                .name {
                    font-size: 2.4rem;
                    margin-bottom: -1rem;
                }
            }
        }

        &.bet-placed {
            transition: all 0.3s ease;
            background-color: #ebebeb;
            border: 0.1rem solid #ffffff;

            .player {
                .name {
                    color: #202020;
                }
            }
        }

        &.current-turn {
            transition: all 0.3s ease;
            border: ${({ action }) => {
                switch (action) {
                    case '-':
                        return '0.5rem solid #FF1C1C';
                    case '+':
                        return '0.5rem solid #01FF39';
                    case 'x2':
                        return '0.5rem solid RGB(225, 173, 1)';
                    case '/':
                        return '0.5rem solid #8F00FF';
                }
            }};
            background-color: #032799;
            height: 14.3rem;
            width: 27rem;
            border-radius: 7.15rem;

            .player {
                .action {
                    color: ${({ action }) => {
                        switch (action) {
                            case '-':
                                return '#FF1C1C';
                            case '+':
                                return '#01FF39';
                            case 'x2':
                                return 'RGB(225, 173, 1)';
                            case '/':
                                return '#8F00FF';
                        }
                    }};
                }
                .name {
                    color: #ffffff;
                    font-size: ${({ action }) => {
                        if (action !== '') return '4.5rem';
                        else return '5rem';
                    }};
                    font-family: 'Chakra Petch';
                    text-transform: uppercase;
                }
            }
        }
    }
`;

export interface BoxProps {
    playerBaseBet: number;
    playerName: string;
    playerLastActions: [PlayerAction | null, PlayerAction | null];
    playerScores: IPlayerScore[];
    playerSeat: number;
    currentState: CurrentState;
}

export const Box = (props: BoxProps) => {
    const {
        playerBaseBet,
        playerName,
        playerLastActions,
        playerScores,
        playerSeat,
        currentState,
    } = props;

    const currentTurn = playerSeat === currentState.seat_id;

    const showAction =
        (currentState.seat_id !== -1 &&
            playerSeat > currentState.seat_id &&
            currentState.next_state !==
                BlackJackStates.WaitingForPlayerAction) ||
        (currentState.next_state !== BlackJackStates.WaitingForPlayerAction &&
            currentState.seat_id !== -1 &&
            currentTurn);

    const boxState = useMemo(() => {
        if (currentTurn) return 'current-turn';
        if (playerBaseBet > 0) return 'bet-placed';
    }, [currentTurn, playerBaseBet]);

    const lastAction = getLastAction(playerScores, playerLastActions, 0);

    const blackJack = lastAction === 'BJ';

    return (
        <Container action={showAction && lastAction ? lastAction : ''}>
            <div data-testId="seat" className={clsx('seat', boxState)}>
                <div
                    data-testId="player"
                    className={clsx('player', blackJack && 'blackjack')}
                >
                    {
                        <div data-testId="name" className={'name'}>
                            {playerName}
                        </div>
                    }
                    {blackJack ? (
                        <div data-testId="blackJack">
                            <BlackJack />
                        </div>
                    ) : (
                        <div>
                            {showAction && (
                                <div data-testId="action" className="action">
                                    {getLastAction(
                                        playerScores,
                                        playerLastActions,
                                        0
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};
