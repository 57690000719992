import styled from '@emotion/styled';

import { PlayerAction } from '@blackjack/models';

const Container = styled.div<{ action: string | null }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 13.9rem;
  width: 40.4rem;

  border-radius: 6.95rem;
  border-color: #ffffff;

  font-size: 6.5rem;
  font-family: sans-serif;

  color: black;

  background-color: ${({ action }) => {
    switch (action) {
      case 'STAND':
        return '#FF1C1C';
      case 'HIT':
        return '#01FF39';
      case 'DOUBLE':
        return 'RGB(225, 173, 1)';
      case 'SPLIT':
        return '#8F00FF';
    }
  }};
`;

interface Props {
  lastAction: PlayerAction | null;
}

export const PlayerActionDisplay = (props: Props) => {
  const { lastAction } = props;

  const actions = ['STAND', 'HIT', 'DOUBLE', 'SPLIT'];
  const validAction = lastAction && actions.includes(lastAction);

  const lastActionSymbol = (lastAction: PlayerAction | '') => {
    console.log('ACTION: ', lastAction);
    switch (lastAction) {
      case PlayerAction.HIT:
        return '+';
      case PlayerAction.STAND:
        return '-';
      case PlayerAction.DOUBLE:
        return 'x2';
      case PlayerAction.SPLIT:
        return '/';
      default:
        return '';
    }
  };

  if (validAction) {
    return (
      <Container
        data-testId="player-action"
        action={lastAction}
      >{`${lastAction} ${lastActionSymbol(lastAction)}`}</Container>
    );
  } else {
    return null;
  }
};
