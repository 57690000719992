import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { WebSocketProvider } from '@shared/ui/contexts';

import { App } from './app/app';
// App default styles
import '@shared/assets/styles/style.css';

import { GameContextProvider } from './app/contexts/game-context';

import './sentry.config';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const url = new URLSearchParams(window.location.search);
const token = url.get('token');

root.render(
    <StrictMode>
        <WebSocketProvider token={token || ''} useTokenHost>
            <GameContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </GameContextProvider>
        </WebSocketProvider>
    </StrictMode>
);
