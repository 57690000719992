import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useReducer,
} from 'react';

import { useWebsocketContext } from '@shared/ui/contexts';

import {
    BlackJackStates,
    Events,
    GameStateEvent,
    GameInfoData,
    GameInfoEvent,
    PlayerData,
    PlayersEvent,
    PlayerTurnData,
    PlayerTurnEvent,
    RoundEndEvent,
    RoundStartEvent,
} from '@blackjack/models';
import { log } from '@shared/utils';
import { IBaseEvent } from '@shared/events';
import { RoundData } from '../types/round-data';

interface GameContextProps {
    gameState: GameState;
}

interface GameProviderProps {
    children: ReactNode;
}

export type CurrentState = {
    previous_state: BlackJackStates | null;
    next_state: BlackJackStates | null;
    seat_id: number;
    hand: number;
};

export type BoxData = {
    hand: number;
    next_state: BlackJackStates | null;
    seatNumber: number;
    hasPlayer: boolean;
    player: PlayerData | undefined;
    playerTurn: boolean;
};

const GameContext = createContext<GameContextProps | undefined>(undefined);

interface GameState {
    dealer: PlayerData | null;
    players: PlayerData[];
    gameInfo: GameInfoData | null;
    roundInfo: RoundData | null;
    currentState: CurrentState;
    currentAction: PlayerTurnData | null;
    payout: RoundData | null;
    insurance: boolean | null;
    boxData: BoxData[] | undefined;
}

const initialState: GameState = {
    players: [],
    gameInfo: null,
    dealer: null,
    roundInfo: null,
    currentState: {
        next_state: BlackJackStates.WaitingForPlayers,
        previous_state: null,
        seat_id: -1,
        hand: 0,
    },
    currentAction: null,
    payout: null,
    insurance: null,
    boxData: undefined,
};

type GameReducer = (
    state: GameState,
    event: IBaseEvent<Events, unknown>[1]
) => GameState;

const gameReducer: GameReducer = (state, event) => {
    switch (event.event) {
        case Events.OfferInsurance: {
            // const eventData = event as PlayerInsuranceEvent;
            // console.log('Insurance', eventData.payload);
            return {
                ...state,
                insurance: true,
            };
        }
        case Events.Players: {
            const eventData = event as PlayersEvent;
            // console.log('Players', eventData.payload);
            return {
                ...state,
                insurance: false,
                dealer: eventData.payload.dealer,
                players: eventData.payload.players,
                boxData: buildBoxes({
                    currentState: state.currentState,
                    players: eventData.payload.players,
                }),
            };
        }
        case Events.GameInfo: {
            const eventData = event as GameInfoEvent;
            return {
                ...state,
                gameInfo: eventData.payload,
            };
        }
        case Events.RoundStart: {
            const eventData = event as RoundStartEvent;
            // console.log('RoundStart', eventData.payload);
            return {
                ...state,
                roundInfo: eventData.payload,
                payout: null,
            };
        }
        case Events.GameState: {
            const eventData = event as GameStateEvent;
            // console.log('GameState', eventData.payload);
            return {
                ...state,
                currentState: eventData.payload,
            };
        }
        case Events.PlayerTurn: {
            const eventData = event as PlayerTurnEvent;
            // console.log('PlayerTurn', eventData.payload);
            return {
                ...state,
                currentAction: eventData.payload,
            };
        }
        case Events.RoundEnd: {
            const eventData = event as RoundEndEvent;
            // console.log('RoundEnd', eventData.payload);
            return {
                ...initialState,
                payout: eventData.payload,
                currentState: state.currentState,
            };
        }
        default:
            return state;
    }
};

const GameContextProvider = (props: GameProviderProps) => {
    const { children } = props;

    const [gameState, dispatch] = useReducer(gameReducer, initialState);

    const { socket } = useWebsocketContext();

    useEffect(() => {
        socket.on('connect', () => {
            console.log('a user connected');
        });

        socket.on(Events.OfferInsurance, (offerInsurance) => {
            // console.log('OfferInsurance: ', offerInsurance);
            log.socket(Events.OfferInsurance, offerInsurance);
            dispatch(offerInsurance);
        });

        socket.on(Events.GameInfo, (gameInfo) => {
            // console.log('GameInfo:', gameInfo);
            dispatch(gameInfo);
        });

        socket.on(Events.RoundStart, (roundStartEvent) => {
            log.socket(Events.RoundStart, roundStartEvent);
            dispatch(roundStartEvent);
        });

        socket.on(Events.Players, (playerEvent) => {
            log.socket(Events.Players);
            dispatch(playerEvent);
        });

        socket.on(Events.GameState, (gmeState) => {
            log.socket(Events.GameState, gmeState);
            dispatch(gmeState);
        });

        socket.on(Events.PlayerTurn, (playerTurn) => {
            log.socket(Events.PlayerTurn, playerTurn);
            dispatch(playerTurn);
        });

        socket.on(Events.RoundEnd, (roundEnd) => {
            log.socket(Events.RoundEnd, roundEnd);
            dispatch(roundEnd);
        });

        socket.connect();

        return () => {
            socket.disconnect();
            socket.off(Events.RoundStart);
            socket.off(Events.Players);
            socket.off(Events.GameState);
            socket.off(Events.PlayerTurn);
            socket.off(Events.RoundEnd);
        };
    }, [socket]);

    //TODO: Remove testing only
    // useEffect(() => {
    //   console.log('GameState: ', gameState);
    // }, [gameState]);

    return (
        <GameContext.Provider
            value={{
                gameState,
            }}
        >
            {children}
        </GameContext.Provider>
    );
};

const useGameContext = (): GameContextProps => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useSocket must be used within a WebSocketProvider');
    }
    return context;
};

export { GameContextProvider, useGameContext };

const buildBoxes = (data: {
    currentState: CurrentState;
    players: PlayerData[];
}) => {
    const { currentState, players } = data;

    const TOTAL_SEATS = 7;
    const s = [];
    const waitingForPlayerAction = currentState.seat_id;
    let playerTurn = -1;

    for (let i = 0; i < TOTAL_SEATS; i++) {
        const player = players.find((p) => p.seat === i);

        if (player) {
            playerTurn = i;
        }

        s.push({
            hand: currentState.hand,
            next_state: currentState.next_state,
            seatNumber: i,
            hasPlayer: !!player,
            player,
            playerTurn: playerTurn === waitingForPlayerAction,
        });
    }
    return s;
};
