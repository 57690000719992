import { useMemo } from 'react';
import styled from '@emotion/styled';

import { SplitBox } from './split-box';
import { SideBets } from './side-bets';
import { Box } from './box';
import { CurrentState } from '../contexts/game-context';
import {
  PlayerAction,
  IPlayerScore,
  IPlayerWins,
  PlayerState,
} from '@blackjack/models';

const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;

  height: 14.6rem;
  width: 36.4rem;
`;

const EmptyBoxContainer = styled.div`
  height: 10.7rem;
  width: 20rem;

  border: 0.3rem solid #3a3a3a;
  border-radius: 5.35rem;
  background-color: #111111;
`;

type PlayerBoxData = {
  playerBaseBet: number;
  playerName: string;
  playerLastActions: [PlayerAction | null, PlayerAction | null];
  playerScores: IPlayerScore[];
  playerSeat: number;
  playerWinAmounts: IPlayerWins;
  playerState: PlayerState;
};

type BoxLayoutProps = {
  hand: number;
  seatNumber: number;
  playerBoxData?: PlayerBoxData;
};

interface LayoutProps {
  box: BoxLayoutProps;
  currentState: CurrentState;
}

export const BoxLayout = (props: LayoutProps) => {
  const { currentState, box } = props;
  const { hand, seatNumber, playerBoxData } = box;

  const isSplit = useMemo(() => {
    if (currentState.seat_id !== seatNumber) return false;
    if (
      playerBoxData &&
      playerBoxData.playerScores.length > 1 &&
      playerBoxData.playerState !== PlayerState.WAITING &&
      playerBoxData.playerScores[1].min > 0
    )
      return true;
    return false;
  }, [playerBoxData, currentState, seatNumber]);

  if (!playerBoxData) {
    return (
      <LayoutContainer>
        <EmptyBoxContainer />
      </LayoutContainer>
    );
  } else {
    const boxProps = {
      playerBaseBet: playerBoxData.playerBaseBet,
      playerName: playerBoxData.playerName,
      playerLastActions: playerBoxData.playerLastActions,
      playerScores: playerBoxData.playerScores,
      playerSeat: playerBoxData.playerSeat,
      currentState,
    };

    const splitBoxProps = {
      hand,
      isSplit,
      playerLastActions: playerBoxData.playerLastActions,
      playerName: playerBoxData.playerName,
      playerScores: playerBoxData.playerScores,
      seatNumber,
      currentState,
    };

    const sideBetsProps = {
      hasTwentyOne: playerBoxData.playerWinAmounts.twenty_one > 0,
      hasPerfectPairs: playerBoxData.playerWinAmounts.perfect_pairs > 0,
    };

    return (
      <LayoutContainer>
        <SideBets {...sideBetsProps}>
          {isSplit ? <SplitBox {...splitBoxProps} /> : <Box {...boxProps} />}
        </SideBets>
      </LayoutContainer>
    );
  }
};
