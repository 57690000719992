import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

export interface TimerProps {
  endTime: number;
  onFinish?: () => void;
}

const Container = styled.div<{ endTime: number | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16rem;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 600;
  color: #ffffff;
`;

export const Timer = (props: TimerProps) => {
  const { endTime, onFinish } = props;

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const [remaining, setRemaining] = useState(Math.round(endTime - Date.now()));

  useEffect(() => {
    const updateTimer = () => {
      timerRef.current = setTimeout(() => {
        const re = endTime - Date.now();
        setRemaining(Math.max(0, re));

        if (re <= 0) {
          if (onFinish) onFinish();
        } else {
          updateTimer();
        }
      }, 100);
    };

    if (endTime) {
      updateTimer();
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [onFinish, endTime]);
  return (
    <Container endTime={endTime}>
      <Number data-testId="dealer-timer">{Math.ceil(remaining / 1000)}</Number>
    </Container>
  );
};
