import { ReactNode } from 'react';

import clsx from 'clsx';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Chakra Petch';
  font-size: 3.5rem;
  color: black;
  user-select: none;

  .sidebet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;
    background-color: black;
    border-color: black;

    &.active {
      color: #202020;
      background-color: #01ff39;
      border-color: #01ff39;
    }

    &.twentyone-plus-3 {
      margin-right: 1rem;
    }

    &.perfect-pairs {
      margin-left: 1rem;
    }
  }
`;

interface Props {
  hasTwentyOne: boolean;
  hasPerfectPairs: boolean;
  children: ReactNode;
}

export const SideBets = (props: Props) => {
  const { hasTwentyOne, hasPerfectPairs, children } = props;

  return (
    <Container>
      <div
        data-testId="twentyOne"
        className={clsx('sidebet', hasTwentyOne && 'active twentyone-plus-3')}
      >
        21
      </div>

      {children}

      <div
        data-testId="perfectPairs"
        className={clsx('sidebet', hasPerfectPairs && 'active perfect-pairs')}
      >
        PP
      </div>
    </Container>
  );
};
