import React from 'react';
import styled from '@emotion/styled';

import { PlayerData } from '@blackjack/models';

const calculateWinAmount = ({
  base,
  perfect_pairs,
  twenty_one,
  insurance,
}: {
  base: number;
  perfect_pairs: number;
  twenty_one: number;
  insurance: number;
}) => {
  return base + perfect_pairs + twenty_one + insurance;
};

const BasicContainer = styled.div`
  display: flex;
  height: 100%;

  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  background-color: black;
  color: #ebebeb;
  font-family: 'Chakra Petch';

  .winner-text {
    color: #01ff39;
    font-size: 16rem;
    font-family: 'Chakra Petch';
  }

  .sub-text {
    margin-top: -2rem;
    font-size: 4rem;
    text-transform: uppercase;
  }

  .players {
    margin-top: 5rem;
    font-size: 16rem;
    text-transform: uppercase;
    text-align: center;
  }
`;

interface RoundEndProps {
  players: PlayerData[];
}

export const RoundEnd = (props: RoundEndProps) => {
  const { players } = props;

  type Winner = {
    name: string;
    user_id: string;
  };

  const winners: Winner[] = [];

  players.forEach((player) => {
    const playerExists = winners.find(
      (winner) => winner.user_id === player.user_id
    );

    if (!playerExists) {
      if (calculateWinAmount(player.winAmounts) > 0) {
        winners.push({
          name: player.name,
          user_id: player.user_id,
        });
      }
    }
  });

  const winnersNames = winners.map((player) => player.name);

  const winnersNamesText = winnersNames.join(' - ');

  const winnerTitleText =
    winners.length === 1
      ? 'WINNER'
      : winners.length > 1
      ? 'WINNERS'
      : 'NO WINNER';

  return (
    <BasicContainer>
      <h1 data-testid="winner-text" className="winner-text">
        {winnerTitleText}
      </h1>
      <h3 data-testid="sub-text" className="sub-text">
        Waiting for next game...
      </h3>

      <p data-testid="players" className="players">
        {winnersNamesText}
      </p>
    </BasicContainer>
  );
};
