import { BlackJackStates } from '@blackjack/models';

import { NumberLocation } from './number-location';
import { BoxNumber } from './dealer-table-number';

export const BoxNumbers = ({
  boxes,
  nextState,
}: {
  boxes?: {
    currentTurn: boolean;
    playerBaseBet: number | undefined;
    seatNumber: number;
  }[];
  nextState: BlackJackStates | null;
}) => {
  const result = boxes?.map((box) => {
    const boxNumberProps = {
      ...box,
      nextState,
    };
    return (
      <NumberLocation seatNumber={box.seatNumber}>
        <BoxNumber {...boxNumberProps} />
      </NumberLocation>
    );
  });

  return <div>{result}</div>;
};
