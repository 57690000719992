import styled from '@emotion/styled';

import { BoxData, CurrentState } from '../contexts/game-context';

import { BoxLocation } from './box-location';
import { BoxLayout } from '../components/box-layout';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayerBoxes = (props: {
  boxes?: BoxData[];
  currentState: CurrentState;
}) => {
  const { boxes, currentState } = props;

  return (
    <Container>
      {boxes?.map((box) => {
        if (!box.player) {
          const boxProps = {
            hand: box.hand,
            seatNumber: box.seatNumber,
          };

          return (
            <div key={box.seatNumber}>
              <BoxLocation seatLocation={box.seatNumber}>
                <BoxLayout box={{ ...boxProps }} currentState={currentState} />
              </BoxLocation>
            </div>
          );
        } else {
          const boxProps = {
            hand: box.hand,
            seatNumber: box.seatNumber,
            playerBoxData: {
              playerBaseBet: box.player?.bets.base,
              playerName: box.player?.name,
              playerLastActions: box.player?.lastActions,
              playerScores: box.player?.scores,
              playerSeat: box.player?.seat,
              playerWinAmounts: box.player?.winAmounts,
              playerState: box.player?.state,
            },
          };

          return (
            <div key={box.seatNumber}>
              <BoxLocation seatLocation={box.seatNumber}>
                <BoxLayout box={{ ...boxProps }} currentState={currentState} />
              </BoxLocation>
            </div>
          );
        }
      })}
    </Container>
  );
};
