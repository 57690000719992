import styled from '@emotion/styled';
import { ReactNode } from 'react';

const Container = styled.div<{ location?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  ${({ location }) => {
    return location;
  }};
`;

const boxLocation = (seatNumber: number) => {
  switch (seatNumber) {
    case 0:
      return `
        top: 43rem;
        left: 7rem;
      `;
    case 1:
      return `
        top: 26rem;
        left: 12rem;
      `;
    case 2:
      return `
        top: 10rem;
        left: 26rem;
      `;
    case 3:
      return `
        top: 4rem;
        left: 49rem;
      `;
    case 4:
      return `
        top: 10rem;
        left: 72rem;
      `;
    case 5:
      return `
        top: 26rem;
        left: 89rem;
      `;
    case 6:
      return `
        top: 43rem;
        left: 95rem;
      `;
    default:
      return `
        top: 0rem;
        left: 0rem;
      `;
  }
};

interface Props {
  seatNumber: number;
  children: ReactNode;
}

export const NumberLocation = (props: Props) => {
  const { seatNumber, children } = props;

  return <Container location={boxLocation(seatNumber)}>{children}</Container>;
};
