import { PlayerAction, IPlayerScore } from '@blackjack/models';

export const getLastAction = (
  playerScores: IPlayerScore[],
  actions: [PlayerAction | null, PlayerAction | null],
  hand: number
) => {
  if (playerScores[hand]?.blackjack) return 'BJ';
  if (playerScores[hand]?.max === 21) return '21';

  if (playerScores[hand]?.min > 21) return 'BUST';

  switch (actions[hand]) {
    case PlayerAction.HIT:
      return '+';
    case PlayerAction.STAND:
      return '-';
    case PlayerAction.DOUBLE:
      return 'x2';
    case PlayerAction.SPLIT:
      return '/';
  }
};
