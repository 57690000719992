import styled from '@emotion/styled';

const Container = styled.span`
  color: #ffffff;
  font-size: 10rem;
  font-family: sans-serif;
`;

interface Props {
  data: string;
}

export const DealerInfo = (props: Props) => {
  const { data } = props;

  return <Container data-testId="dealer-info">{data}</Container>;
};
