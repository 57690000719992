export const boxLocation = (seatNumber: number) => {
  switch (seatNumber) {
    case 0:
      return {
        top: '78rem',
        left: '4rem',
      };
    case 1:
      return {
        top: '52rem',
        left: '10rem',
      };
    case 2:
      return {
        top: '26rem',
        left: '33rem',
      };
    case 3:
      return {
        top: '13rem',
        left: '77.5rem',
      };
    case 4:
      return {
        top: '26rem',
        left: '122.5rem',
      };
    case 5:
      return {
        top: '52rem',
        left: '145.5rem',
      };
    case 6:
      return {
        top: '78rem',
        left: '151.5rem',
      };
  }
};
