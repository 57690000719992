import styled from '@emotion/styled';
import { useMemo } from 'react';

import { BlackJackStates } from '@blackjack/models';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .seat-number {
    font-size: 6.5rem;
    font-family: 'Chakra Petch';
    color: #606161;
  }

  &.has-bet {
    .seat-number {
      color: #ffffff;
    }
  }

  &.current-turn {
    .seat-number {
      color: #2eff1c;
      font-size: 9rem;
    }
  }
`;

interface Props {
  seatNumber: number;
  playerBaseBet?: number;
  currentTurn: boolean;
  nextState: BlackJackStates | null;
}

export const BoxNumber = (props: Props) => {
  const { currentTurn, playerBaseBet, seatNumber, nextState } = props;

  const numberState = useMemo(() => {
    if (!playerBaseBet) return 'empty';
    if (currentTurn && nextState !== BlackJackStates.PlayerEndTurn)
      return 'current-turn';
    if (playerBaseBet > 0) return 'has-bet';
  }, [currentTurn, playerBaseBet, nextState]);

  return (
    <Container className={numberState}>
      <div data-testId="seat-number" className="seat-number">
        {seatNumber + 1}
      </div>
    </Container>
  );
};
